<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/customer"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/customer" class="is-active"><a>Klienci</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
        <div v-if="selectACustomer" id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <a @click="exit" class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></a>
            <div>
              <h2 class="title level-left">Wybierz klienta</h2>
            </div>
          </div>
          <!-- 
			<div class='buttons level-right'>
				<router-link tag="a" class="button is-medium" to="/records/customer/add"><icon name="plus"></icon><span>Dodaj nowego klienta</span></router-link>
			</div>
		-->
        </div>
        <div v-else id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">Klienci</h2>
          <!-- 
          <div class='buttons level-right'>
            <router-link tag="a" class="button is-medium is-dark" to="/records/customer/add"><icon name="plus"></icon><span>Dodaj nowego klienta</span></router-link>
          </div>
		-->
        </div>
      </fixed-header>
    </div>
    <div class="ym-columns is-padding-mobile">
      <div class="column is-12-mobile">
        <div class="ym-searchbox">
          <b-input
            ref="searchInput"
            v-model="searchQuery"
            size="is-medium"
            placeholder="SZUKAJ..."
          ></b-input>
          <div
            class="field level level-item level-right ym-searchbox-right"
            v-if="this.searchQuery"
          >
            również nieaktywni
            <b-checkbox
              v-model="searchDeleted"
              size="is-medium"
              true-value="1"
              false-value="0"
              @change.native="toggleSearchDeleted"
            >
            </b-checkbox>
          </div>
        </div>
      </div>
      <div class="column is-12 is-gapless table-service">
        <b-table
          :data="customers"
          :mobile-cards="true"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column field="name" label="Nazwa" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.name }}</a>
          </b-table-column>
          <b-table-column field="city" label="Miasto" sortable v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.city }}</a>
          </b-table-column>
          <b-table-column field="address" label="Adres" v-slot="props">
            <a @click.prevent="details(props.row)"
              >{{ props.row.address }}, {{ props.row.postcode }}
              {{ props.row.city }}</a
            >
          </b-table-column>
          <b-table-column field="nip" label="NIP" v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.nip }}</a>
          </b-table-column>
          <b-table-column field="phone" label="Telefon" v-slot="props">
            <a @click.prevent="details(props.row)">{{ props.row.phone }}</a>
          </b-table-column>
          <b-table-column field="" label="" width="100" v-slot="props">
            <router-link
              tag="a"
              class="button is-medium is-black is-hidden-tablet"
              :to="{ name: 'CustomerDetails', params: { id: props.row.id } }"
              style="
                margin-top: 4px;
                padding-left: 8px;
                height: 40px !important;
                min-height: 40px !important;
              "
              ><icon name="search"></icon><span>Szczegóły</span></router-link
            >

            <!-- <span v-if="props.row.is_active === true && selectACustomer === false" class="button is-small40" @click="customerRemove(props.row.id)"><div >Usuń</div></span>  -->
            <router-link
              v-if="selectACustomer === true"
              tag="a"
              class="button is-small40"
              :to="{ name: 'CustomerDetails', params: { id: props.row.id } }"
              >więcej</router-link
            >
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div
                class="content"
                style="
                  justify-content: center;
                  font-weight: 600;
                  font-size: 16px;
                  color: #000;
                "
                v-if="!loading"
              >
                <p>
                  <i
                    class="mdi mdi-magnify mdi-24px"
                    style="position: relative; top: 4px; margin-right: 16px"
                  ></i>
                  Brak wyników wyszukiwania.
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "Customer",
  components: {
    FixedHeader,
  },
  data() {
    return {
      customers: [],
      loading: false,
      total: 0,
      sortField: "name",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: 50,
      searchQuery: "",
      searchDeleted: 0,
      selected: null,
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
  },
  mounted() {
    this.$refs.searchInput.$el.children[0].focus();
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getCustomers, 500);
    this.getCustomers();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    ...mapGetters({
      selectACustomer: "orders/selectACustomer",
      order: "orders/order",
    }),
    trows() {
      return document.querySelector("table").rows;
    },
  },
  methods: {
    ...mapActions({
      getCustomersCall: "records/getCustomers",
      removeCustomerCall: "records/removeCustomer",
    }),
    getCustomers() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
      } = this;
      this.getCustomersCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
      })
        .then((resp) => {
          this.total = resp.page.items;
          if (this.total <= this.perPage) {
            document.querySelector(".pagination").style.display = "none";
          }
          this.customers = resp.data;
        })
        .catch((error) => {
          this.customers = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    customerRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć klienta?",
          content: `<span class='is-danger'>Usunięcia klienta nie można cofnąć.</span> Klient pozostanie w utworzonych do tej pory zleceniach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeCustomerCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getCustomers();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    onPageChange(page) {
      this.page = page;
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getCustomers();
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getCustomers();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted

        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          if (gethighlight && trow.className === "is-selected") {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.className = "normal";
          }
        } //end while

        return gethighlight
          ? null
          : (this.className =
              this.className === "is-selected" ? "normal" : "is-selected");
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    exit() {
      this.$store.dispatch("orders/setSelectACustomer", false).then(() => {
        this.$router.push({ name: "ServiceAdd" });
      });
    },
    details(customer) {
      if (this.selectACustomer) {
        this.$store.dispatch("orders/setCustomer", customer).then(() => {
          if (this.order && this.order.id) {
            this.$router.push({
              name: "ServiceEdit",
              params: { id: parseInt(this.order.id) },
            });
          } else {
            this.$store.dispatch("orders/setOrder", {
              device_address: `${customer.address}, ${customer.postcode} ${customer.city}`,
            });
            this.$router.push({ name: "ServiceAdd" });
          }
        });
      } else {
        this.$router.push({
          name: "CustomerDetails",
          params: { id: customer.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
